import { getFrameworkVars } from "@sparky/framework";
import { isAvailable } from "../utils/isAvailable";

export function getTsgState() {
    const { user_preferences_ui, pdf_mode } = getFrameworkVars();
    const isUserPreferencesSupported = Boolean(user_preferences_ui);
    const isPDF = Boolean(pdf_mode);
    import("../hooks/spiffy/useSlrAvailable")
        .then((module) => {
            return module.fetchSlrAvailable();
        })
        .then((slrAvailable) => {
            console.info("SLR availability status:", slrAvailable);
        });
    return {
        isAvailable,
        isUserPreferencesSupported,
        isPDF,
    };
}
